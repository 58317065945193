import React, {createContext, useContext, useEffect, useState} from 'react'
import api from 'src/lib/api'

type Props = {
  children: React.ReactNode
}

type SubscriptionContext = {
  currentLimitations: null | {
    billingStartDate: string,
    billingEndDate: string,
    countDocument: number,
    canAddDocument: boolean,
    isPro: boolean
  }
  getUserSubscription?: () => Promise<void>
}

export const SubscriptionContext = createContext<SubscriptionContext>({ currentLimitations: null })

export const SubscriptionProvider = ({ children }: Props) => {
  const [currentLimitations, setCurrentLimitation] = useState<any>(null)
  const getUserSubscription = async () => {
    const token = localStorage.getItem('accessToken')
    if (token) {
      const res = await api.get('/subscriptions/limitations')
      setCurrentLimitation(res.data)
    }
  }
  useEffect(() => {
    getUserSubscription()
  }, [])
  return (
    <SubscriptionContext.Provider
      value={{
        currentLimitations,
        getUserSubscription,
      }}
    >
      {children}
    </SubscriptionContext.Provider>
  )
}
export const useSubscriptionContext = () => useContext(SubscriptionContext)
