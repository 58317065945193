export const CONSTANT_A = 'CONSTANT_A'
export const PRODUCTS = {
  free: 'prod_QN0RPO3JY3VurR',
  business: 'prod_QN0ky90QXk2uFR',
  professional: 'prod_QN0SkLdMLeiMNS',
}

export const PRICES_DEV = {
  FREE: {
    priceId: 'price_1PWGQsLub95iTtLZ3l6Xhf18',
    paymentLink: 'https://buy.stripe.com/test_7sI1520GVdj34ne000',
  },
  PRO: {
    priceId: 'price_1QEuhsLub95iTtLZRoYvfpeH',
    paymentLink: 'https://buy.stripe.com/test_7sI152blza6R9Hy6os',
  },
}

export const PRICES_PROD = {
  FREE: {
    priceId: 'price_1PWGQsLub95iTtLZ3l6Xhf18',
    paymentLink: 'https://buy.stripe.com/test_7sI1520GVdj34ne000',
  },
  PRO: {
    priceId: 'price_1PVanVLub95iTtLZGgMC9Glx',
    paymentLink: 'https://buy.stripe.com/8wMeWh2wocCh8N2aEE',
  },
}

export const PRICES = process.env.NODE_ENV === 'development' ? PRICES_DEV : PRICES_PROD

export const countryOptions = [
  { label: 'Afghanistan', value: 'af', description: 'Afghanistan' },
  { label: 'Albania', value: 'al', description: 'Albania' },
  { label: 'Algeria', value: 'dz', description: 'Algeria' },
  { label: 'Andorra', value: 'ad', description: 'Andorra' },
  { label: 'Angola', value: 'ao', description: 'Angola' },
  { label: 'Antigua and Barbuda', value: 'ag', description: 'Antigua and Barbuda' },
  { label: 'Argentina', value: 'ar', description: 'Argentina' },
  { label: 'Armenia', value: 'am', description: 'Armenia' },
  { label: 'Australia', value: 'au', description: 'Australia' },
  { label: 'Austria', value: 'at', description: 'Austria' },
  { label: 'Azerbaijan', value: 'az', description: 'Azerbaijan' },
  { label: 'Bahamas', value: 'bs', description: 'Bahamas' },
  { label: 'Bahrain', value: 'bh', description: 'Bahrain' },
  { label: 'Bangladesh', value: 'bd', description: 'Bangladesh' },
  { label: 'Barbados', value: 'bb', description: 'Barbados' },
  { label: 'Belarus', value: 'by', description: 'Belarus' },
  { label: 'Belgium', value: 'be', description: 'Belgium' },
  { label: 'Belize', value: 'bz', description: 'Belize' },
  { label: 'Benin', value: 'bj', description: 'Benin' },
  { label: 'Bhutan', value: 'bt', description: 'Bhutan' },
  { label: 'Bolivia', value: 'bo', description: 'Bolivia' },
  { label: 'Bosnia and Herzegovina', value: 'ba', description: 'Bosnia and Herzegovina' },
  { label: 'Botswana', value: 'bw', description: 'Botswana' },
  { label: 'Brazil', value: 'br', description: 'Brazil' },
  { label: 'Brunei', value: 'bn', description: 'Brunei' },
  { label: 'Bulgaria', value: 'bg', description: 'Bulgaria' },
  { label: 'Burkina Faso', value: 'bf', description: 'Burkina Faso' },
  { label: 'Burundi', value: 'bi', description: 'Burundi' },
  { label: 'Cabo Verde', value: 'cv', description: 'Cabo Verde' },
  { label: 'Cambodia', value: 'kh', description: 'Cambodia' },
  { label: 'Cameroon', value: 'cm', description: 'Cameroon' },
  { label: 'Canada', value: 'ca', description: 'Canada' },
  { label: 'Central African Republic', value: 'cf', description: 'Central African Republic' },
  { label: 'Chad', value: 'td', description: 'Chad' },
  { label: 'Chile', value: 'cl', description: 'Chile' },
  { label: 'China', value: 'cn', description: 'China' },
  { label: 'Colombia', value: 'co', description: 'Colombia' },
  { label: 'Comoros', value: 'km', description: 'Comoros' },
  { label: 'Congo (Congo-Brazzaville)', value: 'cg', description: 'Congo (Congo-Brazzaville)' },
  { label: 'Costa Rica', value: 'cr', description: 'Costa Rica' },
  { label: 'Croatia', value: 'hr', description: 'Croatia' },
  { label: 'Cuba', value: 'cu', description: 'Cuba' },
  { label: 'Cyprus', value: 'cy', description: 'Cyprus' },
  { label: 'Czechia (Czech Republic)', value: 'cz', description: 'Czechia (Czech Republic)' },
  { label: 'Democratic Republic of the Congo', value: 'cd', description: 'Democratic Republic of the Congo' },
  { label: 'Denmark', value: 'dk', description: 'Denmark' },
  { label: 'Djibouti', value: 'dj', description: 'Djibouti' },
  { label: 'Dominica', value: 'dm', description: 'Dominica' },
  { label: 'Dominican Republic', value: 'do', description: 'Dominican Republic' },
  { label: 'Ecuador', value: 'ec', description: 'Ecuador' },
  { label: 'Egypt', value: 'eg', description: 'Egypt' },
  { label: 'El Salvador', value: 'sv', description: 'El Salvador' },
  { label: 'Equatorial Guinea', value: 'gq', description: 'Equatorial Guinea' },
  { label: 'Eritrea', value: 'er', description: 'Eritrea' },
  { label: 'Estonia', value: 'ee', description: 'Estonia' },
  { label: 'Eswatini (fmr. Swaziland)', value: 'sz', description: 'Eswatini (fmr. Swaziland)' },
  { label: 'Ethiopia', value: 'et', description: 'Ethiopia' },
  { label: 'Fiji', value: 'fj', description: 'Fiji' },
  { label: 'Finland', value: 'fi', description: 'Finland' },
  { label: 'France', value: 'fr', description: 'France' },
  { label: 'Gabon', value: 'ga', description: 'Gabon' },
  { label: 'Gambia', value: 'gm', description: 'Gambia' },
  { label: 'Georgia', value: 'ge', description: 'Georgia' },
  { label: 'Germany', value: 'de', description: 'Germany' },
  { label: 'Ghana', value: 'gh', description: 'Ghana' },
  { label: 'Greece', value: 'gr', description: 'Greece' },
  { label: 'Grenada', value: 'gd', description: 'Grenada' },
  { label: 'Guatemala', value: 'gt', description: 'Guatemala' },
  { label: 'Guinea', value: 'gn', description: 'Guinea' },
  { label: 'Guinea-Bissau', value: 'gw', description: 'Guinea-Bissau' },
  { label: 'Guyana', value: 'gy', description: 'Guyana' },
  { label: 'Haiti', value: 'ht', description: 'Haiti' },
  { label: 'Honduras', value: 'hn', description: 'Honduras' },
  { label: 'Hungary', value: 'hu', description: 'Hungary' },
  { label: 'Iceland', value: 'is', description: 'Iceland' },
  { label: 'India', value: 'in', description: 'India' },
  { label: 'Indonesia', value: 'id', description: 'Indonesia' },
  { label: 'Iran', value: 'ir', description: 'Iran' },
  { label: 'Iraq', value: 'iq', description: 'Iraq' },
  { label: 'Ireland', value: 'ie', description: 'Ireland' },
  { label: 'Israel', value: 'il', description: 'Israel' },
  { label: 'Italy', value: 'it', description: 'Italy' },
  { label: 'Jamaica', value: 'jm', description: 'Jamaica' },
  { label: 'Japan', value: 'jp', description: 'Japan' },
  { label: 'Jordan', value: 'jo', description: 'Jordan' },
  { label: 'Kazakhstan', value: 'kz', description: 'Kazakhstan' },
  { label: 'Kenya', value: 'ke', description: 'Kenya' },
  { label: 'Kiribati', value: 'ki', description: 'Kiribati' },
  { label: 'Kuwait', value: 'kw', description: 'Kuwait' },
  { label: 'Kyrgyzstan', value: 'kg', description: 'Kyrgyzstan' },
  { label: 'Laos', value: 'la', description: 'Laos' },
  { label: 'Latvia', value: 'lv', description: 'Latvia' },
  { label: 'Lebanon', value: 'lb', description: 'Lebanon' },
  { label: 'Lesotho', value: 'ls', description: 'Lesotho' },
  { label: 'Liberia', value: 'lr', description: 'Liberia' },
  { label: 'Libya', value: 'ly', description: 'Libya' },
  { label: 'Liechtenstein', value: 'li', description: 'Liechtenstein' },
  { label: 'Lithuania', value: 'lt', description: 'Lithuania' },
  { label: 'Luxembourg', value: 'lu', description: 'Luxembourg' },
  { label: 'Madagascar', value: 'mg', description: 'Madagascar' },
  { label: 'Malawi', value: 'mw', description: 'Malawi' },
  { label: 'Malaysia', value: 'my', description: 'Malaysia' },
  { label: 'Maldives', value: 'mv', description: 'Maldives' },
  { label: 'Mali', value: 'ml', description: 'Mali' },
  { label: 'Malta', value: 'mt', description: 'Malta' },
  { label: 'Marshall Islands', value: 'mh', description: 'Marshall Islands' },
  { label: 'Mauritania', value: 'mr', description: 'Mauritania' },
  { label: 'Mauritius', value: 'mu', description: 'Mauritius' },
  { label: 'Mexico', value: 'mx', description: 'Mexico' },
  { label: 'Micronesia', value: 'fm', description: 'Micronesia' },
  { label: 'Moldova', value: 'md', description: 'Moldova' },
  { label: 'Monaco', value: 'mc', description: 'Monaco' },
  { label: 'Mongolia', value: 'mn', description: 'Mongolia' },
  { label: 'Montenegro', value: 'me', description: 'Montenegro' },
  { label: 'Morocco', value: 'ma', description: 'Morocco' },
  { label: 'Mozambique', value: 'mz', description: 'Mozambique' },
  { label: 'Myanmar (formerly Burma)', value: 'mm', description: 'Myanmar (formerly Burma)' },
  { label: 'Namibia', value: 'na', description: 'Namibia' },
  { label: 'Nauru', value: 'nr', description: 'Nauru' },
  { label: 'Nepal', value: 'np', description: 'Nepal' },
  { label: 'Netherlands', value: 'nl', description: 'Netherlands' },
  { label: 'New Zealand', value: 'nz', description: 'New Zealand' },
  { label: 'Nicaragua', value: 'ni', description: 'Nicaragua' },
  { label: 'Niger', value: 'ne', description: 'Niger' },
  { label: 'Nigeria', value: 'ng', description: 'Nigeria' },
  { label: 'North Korea', value: 'kp', description: 'North Korea' },
  { label: 'North Macedonia', value: 'mk', description: 'North Macedonia' },
  { label: 'Norway', value: 'no', description: 'Norway' },
  { label: 'Oman', value: 'om', description: 'Oman' },
  { label: 'Pakistan', value: 'pk', description: 'Pakistan' },
  { label: 'Palau', value: 'pw', description: 'Palau' },
  { label: 'Palestine State', value: 'ps', description: 'Palestine State' },
  { label: 'Panama', value: 'pa', description: 'Panama' },
  { label: 'Papua New Guinea', value: 'pg', description: 'Papua New Guinea' },
  { label: 'Paraguay', value: 'py', description: 'Paraguay' },
  { label: 'Peru', value: 'pe', description: 'Peru' },
  { label: 'Philippines', value: 'ph', description: 'Philippines' },
  { label: 'Poland', value: 'pl', description: 'Poland' },
  { label: 'Portugal', value: 'pt', description: 'Portugal' },
  { label: 'Qatar', value: 'qa', description: 'Qatar' },
  { label: 'Romania', value: 'ro', description: 'Romania' },
  { label: 'Russia', value: 'ru', description: 'Russia' },
  { label: 'Rwanda', value: 'rw', description: 'Rwanda' },
  { label: 'Saint Kitts and Nevis', value: 'kn', description: 'Saint Kitts and Nevis' },
  { label: 'Saint Lucia', value: 'lc', description: 'Saint Lucia' },
  { label: 'Saint Vincent and the Grenadines', value: 'vc', description: 'Saint Vincent and the Grenadines' },
  { label: 'Samoa', value: 'ws', description: 'Samoa' },
  { label: 'San Marino', value: 'sm', description: 'San Marino' },
  { label: 'Sao Tome and Principe', value: 'st', description: 'Sao Tome and Principe' },
  { label: 'Saudi Arabia', value: 'sa', description: 'Saudi Arabia' },
  { label: 'Senegal', value: 'sn', description: 'Senegal' },
  { label: 'Serbia', value: 'rs', description: 'Serbia' },
  { label: 'Seychelles', value: 'sc', description: 'Seychelles' },
  { label: 'Sierra Leone', value: 'sl', description: 'Sierra Leone' },
  { label: 'Singapore', value: 'sg', description: 'Singapore' },
  { label: 'Slovakia', value: 'sk', description: 'Slovakia' },
  { label: 'Slovenia', value: 'si', description: 'Slovenia' },
  { label: 'Solomon Islands', value: 'sb', description: 'Solomon Islands' },
  { label: 'Somalia', value: 'so', description: 'Somalia' },
  { label: 'South Africa', value: 'za', description: 'South Africa' },
  { label: 'South Korea', value: 'kr', description: 'South Korea' },
  { label: 'South Sudan', value: 'ss', description: 'South Sudan' },
  { label: 'Spain', value: 'es', description: 'Spain' },
  { label: 'Sri Lanka', value: 'lk', description: 'Sri Lanka' },
  { label: 'Sudan', value: 'sd', description: 'Sudan' },
  { label: 'Suriname', value: 'sr', description: 'Suriname' },
  { label: 'Sweden', value: 'se', description: 'Sweden' },
  { label: 'Switzerland', value: 'ch', description: 'Switzerland' },
  { label: 'Syria', value: 'sy', description: 'Syria' },
  { label: 'Taiwan', value: 'tw', description: 'Taiwan' },
  { label: 'Tajikistan', value: 'tj', description: 'Tajikistan' },
  { label: 'Tanzania', value: 'tz', description: 'Tanzania' },
  { label: 'Thailand', value: 'th', description: 'Thailand' },
  { label: 'Timor-Leste', value: 'tl', description: 'Timor-Leste' },
  { label: 'Togo', value: 'tg', description: 'Togo' },
  { label: 'Tonga', value: 'to', description: 'Tonga' },
  { label: 'Trinidad and Tobago', value: 'tt', description: 'Trinidad and Tobago' },
  { label: 'Tunisia', value: 'tn', description: 'Tunisia' },
  { label: 'Turkey', value: 'tr', description: 'Turkey' },
  { label: 'Turkmenistan', value: 'tm', description: 'Turkmenistan' },
  { label: 'Tuvalu', value: 'tv', description: 'Tuvalu' },
  { label: 'Uganda', value: 'ug', description: 'Uganda' },
  { label: 'Ukraine', value: 'ua', description: 'Ukraine' },
  { label: 'United Arab Emirates', value: 'ae', description: 'United Arab Emirates' },
  { label: 'United Kingdom', value: 'gb', description: 'United Kingdom' },
  { label: 'United States of America', value: 'us', description: 'United States of America' },
  { label: 'Uruguay', value: 'uy', description: 'Uruguay' },
  { label: 'Uzbekistan', value: 'uz', description: 'Uzbekistan' },
  { label: 'Vanuatu', value: 'vu', description: 'Vanuatu' },
  { label: 'Vatican City', value: 'va', description: 'Vatican City' },
  { label: 'Venezuela', value: 've', description: 'Venezuela' },
  { label: 'Vietnam', value: 'vn', description: 'Vietnam' },
  { label: 'Yemen', value: 'ye', description: 'Yemen' },
  { label: 'Zambia', value: 'zm', description: 'Zambia' },
  { label: 'Zimbabwe', value: 'zw', description: 'Zimbabwe' },
]
