import React, { ReactNode, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'usehooks-ts'
import {
  cn,
  Button,
  Card,
  CardBody,
  CardFooter,
  ScrollShadow,
  Spacer,
  useDisclosure,
  Image,
  Link,
  Select,
  SelectSection,
  SelectItem,
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Progress,
} from '@nextui-org/react'

import { Icon } from '@iconify/react'

import { items } from '../../components/sidebar/sidebar-items'
import Sidebar from '../../components/sidebar'

import { Outlet, useNavigate, useParams, useLocation } from 'react-router-dom'
import Mixpanel from 'src/lib/mixpanel'
import api from 'src/lib/api'
import { useWorkspaceContextData } from 'src/context/workspace'
import { useSubscriptionContext } from 'src/context/subscription'
import dayjs from 'dayjs'

interface IProps {
  leftNode?: ReactNode
}

export default function Layout(props: IProps) {
  const isCompact = useMediaQuery('(max-width: 768px)')

  const { t } = useTranslation()
  const { isOpen: isCreateOpen, onOpen: onCreateOpen, onOpenChange: onCreateOpenChange } = useDisclosure()
  const { isOpen: openedNewClient, onOpen: openNewClient, onOpenChange: openingNewClient } = useDisclosure()
  const { isOpen: openedNewProject, onOpen: openNewProject, onOpenChange: openingNewProject } = useDisclosure()
  const [workspaceName, setWorkspaceName] = useState<string>('')
  const { client, project }: any = useParams()
  const { activeWorkspace, setActiveWorkspace } = useWorkspaceContextData()
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const [email, setEmail] = useState('')
  const [isUserActive, setsUserActive] = useState(true)
  const [workspaces, setWorkspaces] = useState([
    {
      value: '0',
      label: 'Available workspaces',
      items: [],
    },
  ])
  useEffect(() => {
    const login = localStorage.getItem('email')
    setEmail(login || '')
  }, [])
  const [user, setUser] = useState<any>(null)
  const loadUser = async () => {
    const userString = localStorage.getItem('user')
    if (userString) {
      try {
        setUser(JSON.parse(userString))
      } catch (err) {
        console.log('error parsing user')
      }
    }
    const res = await api.get('/agencies/profile')
    localStorage.setItem('user', JSON.stringify(res.data))
    setUser(res.data)
  }
  const location = useLocation()

  const { getUserSubscription, currentLimitations } = useSubscriptionContext()
  useEffect(() => {
    if (location.pathname !== '/login' && location.pathname !== '/signup' && !user) {
      loadUser()
      getUserSubscription?.()
    }
  }, [location.pathname])

  useEffect(() => {
    if (user) {
      setEmail(user.email)
      const newWorkspaces = (user.workspaces || []).map((workspace: any) => ({
        value: workspace.uuid,
        key: workspace.uuid,
        label: workspace.name ?? workspace.uuid,
      }))
      setWorkspaces([
        {
          value: '0',
          label: 'Available workspaces',
          items: newWorkspaces,
        },
      ])
      if (!activeWorkspace || activeWorkspace === 'null')
        setActiveWorkspace?.(user.defaultWorkspace ?? newWorkspaces?.[0]?.value ?? '')
    }
  }, [user])
  useEffect(() => {
    getUserSubscription?.()
  }, [user])
  const logout = () => {
    localStorage.removeItem('accessToken')
    localStorage.removeItem('refreshToken')
    localStorage.removeItem('email')
    localStorage.clear()
    Mixpanel.logout()
    navigate('/login')
  }

  const resend = async () => {
    api
      .post('/auth/resend', {})
      .then((res) => {
        // TODO - handle success with loading and showing a message
      })
      .catch((e) => {
        // TODO - handle error
      })
  }

  const [creatingWorkspace, setCreatingWorkspace] = useState<boolean>(false)
  const createWorkspace = async () => {
    setCreatingWorkspace(true)

    try {
      const res = await api.post('/workspaces/', {
        name: workspaceName,
      })

      // Track the workspace creation event
      Mixpanel.track('Workspace created', {
        workspace_name: workspaceName,
      })

      await loadUser()
    } catch (error) {
      console.error('Error creating workspace:', error)
    } finally {
      setCreatingWorkspace(false)
      onCreateOpenChange()
    }
  }
  return (
    <>
      <div className="flex h-full">
        {/* 'No mobile support' overlay */}
        <div
          className="flex items-center fixed bottom-0 left-0 right-0 w-full px-2 pb-2 md:hidden"
          style={{ zIndex: 100 }}
        >
          <Card isBlurred className="border-small border-default-200 bg-default-50">
            <CardBody className="flex flex-row gap-2   flex h-full flex-row items-start gap-3 p-4">
              <div
                className={cn(
                  'item-center flex rounded-medium border p-2 bg-default-50 border-default-100 text-default-500',
                )}
              >
                <Icon icon="lucide:badge-alert" width={24} />
              </div>
              <div className="flex flex-col">
                <p className="text-large">Strom AI works best on desktop</p>
                <p className="text-small text-default-500">Switch to your computer to unlock the full experience.</p>
              </div>
            </CardBody>
          </Card>
        </div>
        {/* {!['/', '/signup', '/login', '/home'].includes(pathname) && !pathname.startsWith('/urs') && (
          <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-md flex items-center justify-center z-50 md:hidden">
            <div className="text-center text-white p-4">
              <Icon icon="solar:monitor-smartphone-outline" className="mx-auto mb-4" width={48} />
              <h1 className="text-2xl font-bold pb-2">No mobile support yet</h1>
              <p className="text-lg">Strom AI is optimized for desktop,</p>
              <p className="text-lg">please switch to your computer.</p>
            </div>
          </div>
        )} */}
        {/* END 'No mobile support' overlay */}
        {localStorage.getItem('email') !== null && (
          <div
            className={cn(
              'relative flex h-full min-h-screen w-72 flex-col !border-r-small border-divider p-6 transition-width',
              {
                'w-16 items-center px-2 py-6': isCompact,
              },
            )}
          >
            <div className="flex items-center gap-2 px-2">
              <Link href="/">
                <Image
                  src="/img/strom-logo.png"
                  alt="Strom AI Logo"
                  width={140}
                  radius="none"
                  className={cn({ hidden: isCompact })}
                />
                <Image
                  src="/img/strom-logo-signet.png"
                  alt="Strom AI Logo Icon"
                  width={140}
                  radius="none"
                  className={cn({ hidden: !isCompact })}
                />
              </Link>
            </div>

            {/* Workspace switcher */}
            <Spacer y={8} />
            <div className="flex flex-col gap-y-2">
              <Select
                disableSelectorIconRotation
                aria-label="Select workspace"
                className="px-1"
                classNames={{
                  trigger:
                    'min-h-14 py-6 bg-transparent border-small border-default-200 dark:border-default-100 data-[hover=true]:border-default-500 dark:data-[hover=true]:border-default-200 data-[hover=true]:bg-transparent',
                }}
                popoverProps={{
                  style: { minWidth: '200px' },
                }}
                onChange={(e) => {
                  if (e.target.value && e.target.value !== activeWorkspace.toString()) {
                    // Track the workspace switch
                    Mixpanel.track('Workspace switched', {
                      previous_workspace: activeWorkspace,
                      new_workspace: e.target.value,
                    })

                    setActiveWorkspace?.(e.target.value)
                  }
                }}
                items={workspaces}
                selectedKeys={[activeWorkspace]}
                listboxProps={{
                  className: 'min-w-[150px]',
                  // classNames: {list: "max-h-[200px] overflow-y-auto scrollbar-hide"},
                  topContent: (
                    <Button
                      className="text-center mb-2"
                      size="md"
                      color="secondary"
                      startContent={<Icon icon="lucide:circle-plus" />}
                      onPress={() => {
                        // Track the button click for creating a new workspace
                        Mixpanel.track('Workspace creation intent')

                        // Function to handle workspace creation modal
                        onCreateOpenChange()
                      }}
                    >
                      Create workspace
                    </Button>
                  ),
                }}
                placeholder="Select workspace"
                renderValue={(items) => {
                  return items.map((item) => (
                    <div key={item.key} className={cn('ml-1 flex flex-col gap-y-0.5', { hidden: isCompact })}>
                      <span className="text-tiny leading-4">Workspace</span>
                      <span className="text-tiny text-default-400">{item.data?.label}</span>
                    </div>
                  ))
                }}
                selectorIcon={<Icon color="hsl(var(--nextui-default-500))" icon="lucide:chevrons-up-down" />}
                startContent={
                  <div className="relative h-10 w-10 flex-none rounded-full border-small border-default-300">
                    <Icon className="ml-2 mt-2 text-default-500" icon="solar:users-group-rounded-linear" width={24} />
                  </div>
                }
              >
                {(section) => (
                  <SelectSection
                    key={section.value}
                    hideSelectedIcon
                    aria-label={section.label}
                    items={section.items}
                    title={section.label}
                  >
                    {/* @ts-ignore */}
                    {(item) => (
                      <SelectItem
                        key={item.value}
                        aria-label={item.label}
                        textValue={item.value}
                        isSelected={item.value === activeWorkspace}
                      >
                        <div className="flex flex-row items-center justify-between gap-1">
                          <span>{item.label}</span>
                          <div className="flex h-6 w-6 items-center justify-center rounded-full border-small border-default-300">
                            <Icon className="text-default-500" icon="solar:users-group-rounded-linear" width={12} />
                          </div>
                        </div>
                      </SelectItem>
                    )}
                  </SelectSection>
                )}
              </Select>
            </div>
            {/* END Workspace switcher */}

            <ScrollShadow className="-mr-6 h-full max-h-full py-8 pr-6" hideScrollBar>
              <Sidebar items={items} defaultSelectedKey="home" isCompact={isCompact} />
              <Spacer y={2} />
              {!currentLimitations?.isPro && (
                <Card className={cn('mx-2 overflow-visible', { hidden: isCompact })} shadow="sm">
                  <CardBody className="items-center py-5 text-center">
                    <h3 className="text-medium font-medium text-default-700">
                      Unlock unlimited
                      <span aria-label="rocket-emoji" className="ml-2" role="img">
                        🚀
                      </span>
                    </h3>
                    <p className="p-4 pb-2 text-small text-default-500">
                      Upgrade to Pro for unlimited document creation
                    </p>
                    <span className={'text-xs text-secondary font-bold'}>
                      {Math.max(3 - (currentLimitations?.countDocument ?? 0), 0)}/3 proposals left this month
                    </span>
                    <Progress
                      color="secondary"
                      value={Math.min(100, ((currentLimitations?.countDocument ?? 0) / 3) * 100)}
                      className="max-w-md pb-2"
                    />
                  </CardBody>
                  <CardFooter className="absolute -bottom-8 justify-center">
                    <Button
                      className="px-8"
                      color="secondary"
                      radius="full"
                      onPress={() => window.open('/settings/billing', '_self')}
                    >
                      Upgrade now
                    </Button>
                  </CardFooter>
                </Card>
              )}
            </ScrollShadow>
            <div className="mt-auto flex flex-col">
              <Button
                fullWidth
                className="justify-start text-default-500 data-[hover=true]:text-foreground"
                startContent={<Icon className="text-default-500" icon="solar:help-linear" width={24} />}
                variant="light"
                onPress={() => {
                  // Track button click
                  Mixpanel.track('Help & Support button clicked', {
                    button_name: 'Help & support',
                    page_name: window.location.pathname,
                    location: 'Sidebar',
                  })

                  // Open Help & Support page
                  window.open('https://strom.ai/help')
                }}
              >
                <span className={cn({ hidden: isCompact })}>Help & support</span>
              </Button>
              <Button
                fullWidth
                className="justify-start text-default-500 data-[hover=true]:text-foreground"
                startContent={<Icon className="text-default-500" icon="solar:bug-linear" width={24} />}
                variant="light"
                onPress={() => {
                  // Track button click
                  Mixpanel.track('Feedback button clicked', {
                    button_name: 'Give feedback',
                    page_name: window.location.pathname,
                    location: 'Sidebar',
                  })

                  // Open feedback email
                  window.open('mailto:feedback@strom.ai?subject=Give feedback or report a bug')
                }}
              >
                <span className={cn({ hidden: isCompact })}>Give feedback</span>
              </Button>
              <Button
                className="justify-start text-default-500 data-[hover=true]:text-foreground"
                startContent={<Icon className="text-default-500" icon="solar:logout-3-linear" width={24} />}
                variant="light"
                onPress={() => {
                  // Track button click
                  Mixpanel.track('Log out button clicked', {
                    button_name: 'Log out',
                    page_name: window.location.pathname,
                  })

                  // Log out
                  logout()
                }}
              >
                <span className={cn({ hidden: isCompact })}>Log out</span>
              </Button>
            </div>
          </div>
        )}
        <div className="flex flex-row w-full">
          {/* <div><Header /></div> */}
          <Outlet />
          <Modal isOpen={isCreateOpen} onOpenChange={onCreateOpenChange}>
            <ModalContent>
              {(onClose) => (
                <>
                  <ModalHeader className="flex flex-col gap-1"></ModalHeader>
                  <ModalBody>
                    <div className="flex flex-col grow gap-7">
                      <div className="flex flex-col items-center">
                        <p className="text-2xl font-semibold">Create workspace</p>
                        <p className="text-base text-center">
                          Set up a new workspace, bring your team and clients on board, and keep your projects
                          organized.
                        </p>
                      </div>
                      <div className="flex flex-col gap-3 items-center">
                        <Input
                          isRequired
                          type="text"
                          label="Workspace name"
                          placeholder="e.g. GreenLeaf workspace"
                          className="max-w-xs"
                          onChange={(e) => setWorkspaceName(e.target.value)}
                          autoFocus={true}
                        />
                      </div>
                    </div>
                  </ModalBody>
                  <ModalFooter>
                    <Button color="danger" variant="light" onPress={onClose}>
                      Cancel
                    </Button>
                    <Button color="secondary" onPress={createWorkspace} isDisabled={workspaceName.length === 0}>
                      Create
                    </Button>
                  </ModalFooter>
                </>
              )}
            </ModalContent>
          </Modal>
        </div>
      </div>
    </>
  )
}
