'use client'
import * as React from 'react'
import {
  Button,
  Input,
  RadioGroup,
  Select,
  SelectItem,
  Spacer,
  Link,
  Chip,
  useDisclosure,
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
} from '@nextui-org/react'
import { Icon } from '@iconify/react'

import { cn } from './cn'
import { PlanCustomRadio } from './plan-custom-radio'
import { countryOptions, PRICES } from 'src/lib/constants'
import { useEffect, useRef, useState } from 'react'
import api from 'src/lib/api'
import toast from 'react-hot-toast'
import Mixpanel from 'src/lib/mixpanel'
import { useSocketContextData } from 'src/context/socket'
import { useSubscriptionContext } from 'src/context/subscription'

interface BillingSettingCardProps {
  className?: string
  client: any
  loadUser?: () => void
  hideBillingAddress: boolean
}

const BillingSetting = React.forwardRef<HTMLDivElement, BillingSettingCardProps>(
  ({ className, client, loadUser, hideBillingAddress, ...props }, ref) => {
    const [companyName, setCompanyName] = useState<string>('')
    const [vatNumber, setVatNumber] = useState<string>('')
    const [addressLine1, setAddressLine1] = useState<string>('')
    const [addressLine2, setAddressLine2] = useState<string>('')
    const [city, setCity] = useState<string>('')
    const [country, setCountry] = useState<string>('us')
    const [postalCode, setPostalCode] = useState<string>('')
    const [currentPlan, setCurrentPlan] = useState<string>('FREE')
    const [loading, setLoading] = useState<boolean>(false)
    useEffect(() => {
      if (client) {
        setCompanyName(client.companyName ?? '')
        setVatNumber(client.vatNumber ?? '')
        setAddressLine1(client.addressLine1 ?? '')
        setAddressLine2(client.addressLine2 ?? '')
        setCity(client.city ?? '')
        setCountry(client.country ?? 'us')
        setPostalCode(client.postalCode ?? '')
        setCurrentPlan(client.subscription ?? 'FREE')
      }
    }, [client])
    const { getUserSubscription, currentLimitations } = useSubscriptionContext()
    const { isOpen, onOpen, onClose } = useDisclosure()

    const updateAccount = async () => {
      api
        .post('/agencies/profile', {
          country,
          vatNumber,
          addressLine1,
          addressLine2,
          city,
          postalCode,
          companyName,
        })
        .then((res) => {
          localStorage.setItem('user', JSON.stringify(res.data))
          loadUser?.()
          toast.success('Account details has been updated!', {
            style: {
              borderRadius: '10px',
              background: '#333',
              color: '#fff',
            },
          })
        })
        .catch((e) => {
          console.log('Error loading current profile')
        })
    }
    const planCheckInterval = useRef<any | null>(null)
    const { io } = useSocketContextData()
    return (
      <div ref={ref} className={cn('p-2', className)} {...props}>
        {/* Payment Method */}
        <div>
          <div className="flex justify-center">
            <Chip
              startContent={<Icon icon="lucide:badge-alert" width="18" />}
              color="secondary"
              variant="bordered"
              size="lg"
              className="p-3 mb-8 hidden"
            >
              Free during beta, enjoy all features!
            </Chip>
          </div>
          {/* Current Plan */}
          <div className="">
            <p className="text-base font-medium text-default-700">Current Plan</p>
            <p className="mt-1 text-sm font-normal text-default-400">Switch plans to meet your needs.</p>
            {/* Plan radio group */}
            <RadioGroup
              onChange={(e) => {
                const plan = e.target.value
                const link = PRICES?.[plan]?.paymentLink
                // Track payment intent
                Mixpanel.track('Payment plan selected', {
                  selected_plan: plan,
                })

                try {
                  const userData = JSON.parse(localStorage.getItem('user') as string)
                  if (plan === 'PRO') {
                    // only if pro is selected we open the window
                    const opener = window.open(link + '?prefilled_email=' + userData?.email)
                    planCheckInterval.current = setInterval(async () => {
                      const data = await io.emitWithAck('getMyPlan', {
                        uuid: userData.uuid,
                        token: localStorage.getItem('accessToken'),
                      })
                      if (data?.isPro) {
                        opener?.close?.()
                        getUserSubscription?.()
                        setCurrentPlan(plan)
                        clearInterval(planCheckInterval.current)
                      }
                    }, 500)
                  } else {
                    // open confirmation modal
                    onOpen()
                  }
                } catch (e) {}
              }}
              className="mt-4"
              classNames={{
                wrapper: 'gap-4 flex-row flex-wrap',
              }}
              value={currentPlan}
              orientation="horizontal"
              isDisabled={false}
            >
              <PlanCustomRadio
                classNames={{
                  label: 'text-default-500 font-medium',
                }}
                description="Free"
                value="FREE"
              >
                <div className="mt-2">
                  <p className="py-2">
                    <span className="text-[30px] font-semibold leading-7 text-default-foreground">$0</span>
                    &nbsp;<span className="text-xs font-medium text-default-400 hidden">/ month per editor</span>
                  </p>
                  <ul className="list-inside list-disc text-sm font-normal text-default-500">
                    <li>Create up to 3 documents per month</li>
                    <li>AI-powered autocomplete</li>
                    <li>Questions Picker to spot and fill gaps</li>
                    <li>Use Strom AI Chat for full document edits</li>
                    <li>Export to PDF, Word, and CSV</li>
                    <li>Invite team members (viewers only)</li>
                    <li>Share links with Strom AI branding</li>
                  </ul>
                  <p className="text-xs font-medium text-default-600 pt-3 hidden">Features</p>
                  <ul className="list-inside list-disc text-xs font-normal text-default-500 hidden">
                    <li>Access to basic templates</li>
                    <li>Basic analytics</li>
                  </ul>
                </div>
              </PlanCustomRadio>
              <PlanCustomRadio
                classNames={{
                  label: 'text-default-500 font-medium',
                }}
                description="Pro"
                value="PRO"
              >
                <div className="mt-2">
                  <p className="py-2">
                    <span className="text-[30px] font-semibold leading-7 text-default-foreground">$29</span>
                    &nbsp;<span className="text-xs font-medium text-default-400">/ editor per month</span>
                  </p>
                  <ul className="list-inside list-disc text-sm font-normal text-default-500">
                    <li>Create unlimited documents per month</li>
                    <li>AI-powered autocomplete</li>
                    <li>Questions Picker to spot and fill gaps</li>
                    <li>Use Strom AI Chat for full document edits</li>
                    <li>Export to PDF, Word, and CSV</li>
                    <li>Invite editors with real-time collaboration</li>
                    <li>Share links without Strom AI branding</li>
                    <li>Access team & client analytics and audit logs</li>
                  </ul>
                </div>
              </PlanCustomRadio>
            </RadioGroup>
            <Spacer y={2} />
            <div className="flex py-2 justify-center">
              <p className="text-default-400">
                For more details, visit our &nbsp;
                <Link isExternal color="foreground" href="https://strom.ai/pricing" underline="always" showAnchorIcon>
                  Pricing page
                </Link>
              </p>
            </div>
          </div>
          <Spacer y={4} />
          {/* Billing Address */}
          {!hideBillingAddress && (
            <>
              <div>
                {/*  Title */}
                <div>
                  <p className="text-base font-medium text-default-700">Billing Address</p>
                  <p className="mt-1 text-sm font-normal text-default-400">
                    If you&apos;d like to add a postal address to every invoice, enter it here.
                  </p>
                </div>
              </div>
              <div className="mt-2 space-y-2">
                <div className="flex items-center gap-2">
                  <Input
                    placeholder="Company name"
                    value={companyName}
                    onChange={(e) => setCompanyName(e.target.value)}
                  />
                  <Input placeholder="VAT number" value={vatNumber} onChange={(e) => setVatNumber(e.target.value)} />
                </div>
                <Input
                  placeholder="Address Line 1"
                  value={addressLine1}
                  onChange={(e) => setAddressLine1(e.target.value)}
                />
                <Input
                  placeholder="Address Line 2"
                  value={addressLine2}
                  onChange={(e) => setAddressLine2(e.target.value)}
                />
                <Input placeholder="City" value={city} onChange={(e) => setCity(e.target.value)} />
                <div className="flex items-center gap-2">
                  <Select
                    selectedKeys={[country]}
                    onSelectionChange={(keys) => {
                      const key = Array.from(keys)[0]
                      setCountry((key as string) ?? 'us')
                    }}
                  >
                    {countryOptions.map((countryOption) => (
                      <SelectItem key={countryOption.value} value={countryOption.value}>
                        {countryOption.label}
                      </SelectItem>
                    ))}
                  </Select>
                  <Input placeholder="Postal Code" value={postalCode} onChange={(e) => setPostalCode(e.target.value)} />
                </div>
              </div>
              <Button className="mt-5" color="secondary" size="md" radius="md" onClick={updateAccount}>
                Save
              </Button>
            </>
          )}
        </div>
        <Modal size={'2xl'} isOpen={isOpen} onClose={onClose}>
          <ModalContent>
            {(onClose) => (
              <>
                <ModalHeader className="flex flex-col gap-1">Are You Sure You Want to Downgrade?</ModalHeader>
                <ModalBody>
                  <p className={'text-xl'}>
                    By downgrading your subscription plan, you will lose access to certain premium features. Do you wish
                    to continue with the downgrade?
                  </p>
                </ModalBody>
                <ModalFooter>
                  <Button
                    color="danger"
                    isDisabled={loading}
                    variant={'solid'}
                    onPress={async () => {
                      setLoading(true)
                      await io.emitWithAck('downgradePlan', {
                        token: localStorage.getItem('accessToken'),
                      })
                      getUserSubscription?.()
                      setCurrentPlan('FREE')
                      setLoading(false)
                      onClose()
                    }}
                  >
                    Yes, downgrade my plan {loading && <Spinner size={'sm'} />}
                  </Button>
                  <Button color="success" variant={'bordered'} onPress={onClose}>
                    🚀Keep my Pro Plan
                  </Button>
                </ModalFooter>
              </>
            )}
          </ModalContent>
        </Modal>
      </div>
    )
  },
)

BillingSetting.displayName = 'BillingSetting'

export default BillingSetting
