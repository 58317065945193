import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { Button, Input, Checkbox, Link, Image } from '@nextui-org/react'
import { Icon } from '@iconify/react'
import api from 'src/lib/api'
import React, {useCallback, useEffect, useState} from 'react'
import Mixpanel from 'src/lib/mixpanel'
import { useNavigate } from 'react-router-dom'
import {AxiosError} from "axios";
import {GoogleReCaptcha} from "react-google-recaptcha-v3";

export default function Login() {
  const { t } = useTranslation()

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState('')

  const [isVisible, setIsVisible] = React.useState(false)
  const [reCaptchaToken, setRecaptchaToken] = useState<string>('')
  const handleRecaptchaVerify = useCallback((token) => {
    setRecaptchaToken(token)
    console.log(token)
  }, [])
  const toggleVisibility = () => setIsVisible(!isVisible)
  const navigate = useNavigate()
  const login = async (e: any) => {
    e.preventDefault();
    api
      .post('/auth/signin', { email, password, token: reCaptchaToken })
      .then((res) => {
        console.log('Login', res.data);
        if (res.data?.accessToken && res.data?.refreshToken) {
          localStorage.setItem('accessToken', res.data.accessToken);
          localStorage.setItem('refreshToken', res.data.refreshToken);
          localStorage.setItem('email', res.data.email);
          Mixpanel.login(res.data.uuid);
  
          // Track login success
          Mixpanel.track("User logged in", {
            "email": email,
          });
  
          if (res.data.new) Mixpanel.signup(res.data);
          if (res.data.firstname === '' && res.data.lastname === '') {
            navigate('/settings');
          } else {
            navigate('/');
          }
        } else {
          setError('Login was not successful. Try again.');
          setTimeout(() => setError(''), 3000);
          
          // Track login failure
          Mixpanel.track("Login failed", {
            "email": email,
            "error_message": "No access or refresh token returned"
          });
        }
      })
      .catch((err) => {
        const error = err as AxiosError;
        if (error instanceof AxiosError) {
          const message = error.response?.data?.message || 'Login was not successful.';
          setError(message);
  
          // Track login failure
          Mixpanel.track("Login failed", {
            "email": email,
            "error_message": message
          });
        } else {
          setError('Login was not successful. ' + err.message);
  
          // Track login failure with generic error
          Mixpanel.track("Login failed", {
            "email": email,
            "error_message": err.message
          });
        }
        setTimeout(() => setError(''), 3000);
      });
  };

  const validateEmail = (email: string) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      )
  }
  return (
    <>
      <Helmet>
        <title>{t('title')}</title>
      </Helmet>

      <div className="flex h-full w-full flex-col items-center justify-center px-3">
        <div className="flex flex-col items-center pb-6">
          <Image src="/img/strom-logo.png" alt="strom logo" key={'logo'} width={100} radius="none" className="mb-6" />
          <p className="text-xl font-medium">Welcome back</p>
          <p className="text-small text-default-500">Log in to your account to continue</p>
        </div>
        <div className="mt-2 flex w-full max-w-md flex-col gap-4 rounded-large bg-content1 px-8 py-6 shadow-small">
          <form className="flex flex-col gap-2" onSubmit={(e) => e.preventDefault()}>
            <Input
              label="Email address"
              name="email"
              placeholder="Enter your email"
              type="email"
              variant="bordered"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
            />
            <Input
              endContent={
                <button type="button" onClick={toggleVisibility}>
                  {isVisible ? (
                    <Icon className="pointer-events-none text-2xl text-default-400" icon="solar:eye-closed-linear" />
                  ) : (
                    <Icon className="pointer-events-none text-2xl text-default-400" icon="solar:eye-bold" />
                  )}
                </button>
              }
              label="Password"
              name="password"
              placeholder="Enter your password"
              type={isVisible ? 'text' : 'password'}
              variant="bordered"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <GoogleReCaptcha onVerify={handleRecaptchaVerify} />
            <div className="flex items-center justify-between px-1 py-2">
              <Checkbox name="remember" size="sm">
                Remember me
              </Checkbox>
              <Link className="text-default-500" href="/account/reset" size="sm">
                Forgot password?
              </Link>
            </div>
            <Button
              color="secondary"
              type="submit"
              onClick={login}
              isDisabled={email.length === 0 || password.length < 5 || !validateEmail(email)}
            >
              Log in
            </Button>
            {error && <p className={'text-danger text-center text-sm'}>{error}</p>}
          </form>
          {/* <div className="flex items-center gap-4">
            <Divider className="flex-1" />
            <p className="shrink-0 text-tiny text-default-500">OR</p>
            <Divider className="flex-1" />
          </div>
          <div className="flex flex-col gap-2">
            <Button startContent={<Icon icon="logos:salesforce" width={24} />} variant="bordered">
              Continue with Salesforce (coming soon)
            </Button>
          </div> */}
          <p className="text-center text-small">
            Need to create an account?&nbsp;
            <Link href="/signup" size="sm">
              Sign up
            </Link>
          </p>
        </div>
      </div>
    </>
  )
}
