import mixpanel from 'mixpanel-browser'

mixpanel.init('7f0a04a424de8ab3c9094d0ce39b7dc2', {
  // api_host: 'https://api-eu.mixpanel.com',
  persistence: 'localStorage',
  debug: false,
  track_pageview: "full-url"
})

console.log(`track app`)

const Mixpanel = {
  // Identify a user in session
  login: (userId: number) => {
    console.log(`track login ${userId}`)
    mixpanel.alias(`${userId}`) // Alias/merge the current anonymous Mixpanel distinct_id (that was set automatically by Mixpanel during mixpanel.init) to the user's unique ID
    mixpanel.identify(`${userId}`) // Identify the user with their unique ID
  },

  // Create a user during signup
  signup: (data: any) => {
    console.log(`track signup`, data)
    mixpanel.people.set(data)
  },

  // Alias another user later on
  alias: (userId: number) => {
    console.log(`track alias ${userId}`)
    mixpanel.alias(`${userId}`)
  },

  // Logout an user
  logout: () => {
    console.log(`track logout`)
    mixpanel.reset()
  },

  // General tracking method
  track: (prop: string, info: any = {}) => {
    // Retrieve and attach user ID from local storage if available
    const userString = localStorage.getItem('user');
    if (userString) {
      try {
        const user = JSON.parse(userString);
        // Include user ID in the track event properties
        info = { ...info, user_id: user.uuid };
      } catch (err) {
        console.error('Error parsing user data from local storage', err);
      }
    }

    console.log(`event track "${prop}" with properties: `, JSON.stringify(info, null, 2)); // Log info in a readable format
    mixpanel.track(`${prop}`, info);
  },

  // Track a button click
  btn: (screen: string, action: string, info?: any) => {
    console.log(`track btn ${action} on scr ${screen}`)
    mixpanel.track(`btn_${screen}_${action}`, info)
  },

  // Track a screen/page view
  scr: (screen: string, info?: any) => {
    console.log(`track scr ${screen}`)
    mixpanel.track(`scr_${screen}`, info)
  },

  // Track an action
  // act: (screen: string, action: string, info: any = {}) => {
  act: (action: string, info: any = {}) => {
    // Set a default value for 'type' if not provided
    const defaultInfo = { ...info, type: info?.type || 'success' };
    console.log(`track act ${action} on ${screen}`);
    mixpanel.track(`${action}`, defaultInfo);
    // mixpanel.track(`act_${screen}_${action}`, defaultInfo);
  },

  // Track an error
  err: (screen: string, action: string, error: string, info?: any) => {
    console.log(`track err ${action} on ${screen}`)
    mixpanel.track(`act_${screen}_${action}_error`, { ...info, type: 'error', error: error })
  },
}

export default Mixpanel
