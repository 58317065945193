import React, { useEffect, useMemo } from 'react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { Route, RouterProvider, createBrowserRouter, createRoutesFromElements, useParams } from 'react-router-dom'

// import Feedback from 'simple-screenshot-feedback'
import { SocketProvider } from './context/socket'
import Layout from './pages/layout' // https://reactrouter.com/en/main/route/route#layout-routes
import Home from './pages/home'
import Login from './pages/login'
import Signup from './pages/signup'
import Instances from './pages/instances'
import Projects from './pages/projects'
import AI from './pages/ai'
import Meeting from './pages/meeting'
import Insights from './pages/insights'
import Download from './pages/download'
import Settings from './pages/settings'
import Team from './pages/team'
import AuditLogs from './pages/audit'
import SupervisorMeetings from './pages/supervisor'
import VerfiyUser from 'src/pages/VerifyUser'
import Pricing from './pages/pricing'
import { WorkspaceProvider } from 'src/context/workspace'
import ResetPassword from 'src/pages/reset'
import Invitation from 'src/pages/invitation'
import UrsList from './pages/ursList'
import UrsPage from 'src/pages/ursPage'
import { MilkdownProvider } from '@milkdown/react'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { googleSiteKey } from './lib/env'
import UrsPageSupervisor from 'src/pages/supervisor/urds'
import Mixpanel from './lib/mixpanel'
import { SubscriptionProvider } from 'src/context/subscription'

const SettingsWithTab = () => {
  const { tab } = useParams()
  return <Settings tab={tab} />
}

export default function App() {
  const queryClient = useMemo(() => new QueryClient({}), [])

  useEffect(() => {
    const userString = localStorage.getItem('user')
    if (userString) {
      try {
        const user = JSON.parse(userString)
        Mixpanel.login(user.uuid)
      } catch (err) {}
    }
  }, [])

  return (
    <GoogleReCaptchaProvider reCaptchaKey={googleSiteKey}>
      <MilkdownProvider>
        <WorkspaceProvider>
          <SocketProvider>
            <QueryClientProvider client={queryClient}>
              <SubscriptionProvider>
                <RouterProvider
                  router={createBrowserRouter(
                    createRoutesFromElements(
                      <Route element={<Layout />}>
                        <Route path="/" element={<Home />} />
                        <Route path="/login" element={<Login />} />
                        <Route path="/signup" element={<Signup />} />
                        <Route path="/invitation/:invitationUUID" element={<Invitation />} />
                        <Route path="/account/reset/:token" element={<ResetPassword />} />
                        <Route path="/verify/user/:token" element={<VerfiyUser />} />
                        <Route path="/verify/user/" element={<VerfiyUser />} />
                        <Route path="/urs" element={<UrsList />} />
                        <Route path="/urs/:ursUUID" element={<UrsPage />} />
                        <Route path="/supervisor/urs/:ursUUID" element={<UrsPageSupervisor />} />
                        <Route
                          path="/instances/:client/projects"
                          element={<Projects />}
                          loader={async () => {
                            console.log('Route loaded client XY')
                            return { uuid: 'test', name: 'XY' }
                          }}
                          handle={{ breadcrumb: (client: any) => ({ client: client.id }) }}
                        />
                        <Route
                          path="/instances/*"
                          element={<Instances />}
                          handle={{ crumb: () => ({ href: `/instances`, name: 'All Instances', key: 'instances' }) }}
                        />
                        <Route
                          path="/projects"
                          element={<Meeting />}
                          handle={{ crumb: () => ({ href: `/project`, name: 'Project list', key: 'projects' }) }}
                        />
                        <Route path="/settings" element={<Settings />} />
                        <Route path="/settings/:tab" element={<SettingsWithTab />} />
                        <Route path="/team" element={<Team />} />
                        <Route path="/audit" element={<AuditLogs />} />
                        <Route
                          path="/supervisor"
                          element={<SupervisorMeetings />}
                          handle={{
                            crumb: () => ({ href: `/supervisor`, name: 'Supervisor View', key: 'supervisor' }),
                          }}
                        />
                        <Route
                          path="/project/:meetingUUID"
                          element={<Insights />}
                          handle={{ crumb: () => ({ href: `/projects`, name: 'Projects', key: 'projects' }) }}
                        />
                        <Route
                          path="/client/:client/projects"
                          element={<Projects />}
                          loader={async () => {
                            console.log('Route loaded client XY')
                            return { uuid: 'test', name: 'XY' }
                          }}
                          handle={{ breadcrumb: (client: any) => ({ client: client.id }) }}
                        />
                        <Route
                          path="/clients/:client/projects/:project"
                          element={<AI />}
                          loader={async () => {
                            console.log('Route loaded project Dashboard')
                            return { uuid: 'proasdsdf', name: 'Dashboard', clientUuid: 'rtesfa' }
                          }}
                          handle={{ crumb: (project: any) => ({ project: project.id, client: project.clientId }) }}
                        />
                        <Route
                          path="/download"
                          element={<Download />}
                          loader={async () => {
                            console.log('Download starting')
                            return {}
                          }}
                        />
                        <Route path="/pricing" element={<Pricing />} />
                      </Route>,
                    ),
                  )}
                />
                {/* <Feedback
                slackToken="xoxb-397530728805-6237601335397-xBkXPySkrvwRzDcFEQzGCMQO"
                slackChannel="#feedback"
                handleSubmitError={(errormsg: any) => console.log(errormsg)}
                location="top-right"
              /> */}
                <ReactQueryDevtools />
              </SubscriptionProvider>
            </QueryClientProvider>
          </SocketProvider>
        </WorkspaceProvider>
      </MilkdownProvider>
    </GoogleReCaptchaProvider>
  )
}
